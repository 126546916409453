import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  ContentType,
  DisplayMode,
  SiteControlWidget,
  Widget,
} from '@nutmeglabs/nutmeg-sitecontrol-ui';
import '@nutmeglabs/nutmeg-sitecontrol-ui/dist/esm/index.css';
import moment from 'moment';

import {
  createSiteControlWidget,
  fetchSiteControlWidget,
  updateSiteControlWidget,
} from 'client/actions/siteControlWidgets';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';
import { getArrayMutators } from 'client/libraries/util/form';
import { StepsNavigation } from 'client/components/StepsNavigation/StepsNavigation';
import baseStyles from 'client/v3-base.module.css';

import styles from './SiteControlWidgetEditor.module.css';
import {
  convertFormValuesToSwagger,
  convertSwaggerToFormValues,
  FormValues,
} from './formValues';
import { useDefaultContent, useDefaultSiteControlWidget } from './util';
import { TemplateEdit } from './Step/TemplateEdit';
import { WidgetSettings } from './Step/WidgetSettings';

type Step = 'TEMPLATE_SELECT' | 'TEMPLATE_EDIT' | 'WIDGET_SETTINGS';

type TemplateOption = {
  text: string;
  value: ContentType;
};

export const SiteControlWidgetEditor = () => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const [step, setStep] = useState<Step>(
    id ? 'TEMPLATE_EDIT' : 'TEMPLATE_SELECT'
  );
  const [newlyCreatedValues, setNewlyCreatedValues] =
    useState<FormValues | null>(null);
  const existingSiteControlWidget = useSelector((state: ReduxState) =>
    state.siteControlWidgets.all.find((s) => s.id === id)
  );
  const lastCreatedSiteControlWidget = useSelector(
    (state: ReduxState) => state.siteControlWidgets.lastCreatedSiteControlWidget
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchSiteControlWidget(id));
    }
  }, [id]);

  useEffect(() => {
    if (newlyCreatedValues && lastCreatedSiteControlWidget?.id) {
      dispatch(
        updateSiteControlWidget(
          lastCreatedSiteControlWidget.id,
          convertFormValuesToSwagger(
            newlyCreatedValues,
            lastCreatedSiteControlWidget.id
          )
        )
      );
    }
  }, [newlyCreatedValues, lastCreatedSiteControlWidget]);

  const initialValues = useMemo<FormValues>(() => {
    if (existingSiteControlWidget) {
      return convertSwaggerToFormValues(existingSiteControlWidget);
    }

    return {
      name: '',
      status: 'DRAFT',
      baseUrl: '',
      content: useDefaultContent('COUPON', t),
      includes: [],
      excludes: [],
      timing: {
        type: 'IMMEDIATELY',
      },
    };
  }, [existingSiteControlWidget]);

  const pageTitle = existingSiteControlWidget
    ? t('Edit Site Hacker')
    : t('New Site Hacker');
  const paths = [
    { text: t('Site Hacker List'), url: '/sitecontrolwidgets' },
    { text: pageTitle, url: '' },
  ];

  const templateOptions: TemplateOption[] = [
    {
      text: t('Promotion', { context: 'site_control' }),
      value: 'COUPON',
    },
    {
      text: t('Card'),
      value: 'CARD',
    },
    {
      text: t('Vertical', { context: 'site_control' }),
      value: 'VERTICAL',
    },
    {
      text: t('Multi buttons'),
      value: 'MULTI_BUTTONS',
    },
  ];

  const steps = [
    { name: 'TEMPLATE_SELECT', text: 'Select Template' },
    { name: 'TEMPLATE_EDIT', text: 'Edit Template' },
    { name: 'WIDGET_SETTINGS', text: 'Widget Settings' },
  ];

  const handleScrollToTop = () => {
    pageRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  const validateFormValues = (values: FormValues) => {
    const errors: string[] = [];

    if (values.schedules && values.schedules.length > 0) {
      for (const schedule of values.schedules) {
        if (schedule?.type === 'DATE') {
          if (
            (schedule.startDate === null && schedule.endDate === null) ||
            (!schedule.startDate && !schedule.endDate)
          ) {
            errors.push(
              `${t('Schedule')}: ${t(
                'Schedule period should have either start date or end date'
              )}`
            );
            continue;
          }

          if (!schedule.startDate || !schedule.endDate) {
            continue;
          }

          if (moment(schedule.startDate).isAfter(moment(schedule.endDate))) {
            errors.push(
              `${t('Schedule')}: ${t(
                'Start date must be equal to or before end date'
              )}`
            );
          }
        }

        if (schedule?.type === 'TIME') {
          if (
            moment(schedule.startTime, 'HH:mm').isAfter(
              moment(schedule.endTime, 'HH:mm')
            )
          ) {
            errors.push(
              `${t('Schedule')}: ${t(
                'Start time must be equal to or before end time'
              )}`
            );
          }
        }

        if (schedule?.type === 'DAYS_OF_WEEK') {
          if (!schedule.daysOfWeek || schedule.daysOfWeek?.length === 0) {
            errors.push(
              `${t('Schedule')}: ${t(
                'At least one days of week must be selected'
              )}`
            );
          }
        }
      }
    }

    return errors;
  };

  if (id && !existingSiteControlWidget) {
    return <Loading size="md" />;
  }

  return (
    <V3Page>
      <PageHeader
        title={pageTitle}
        hideNewUIToggle={true}
        breadcrumbPaths={paths}
      ></PageHeader>
      <PageContent>
        <div
          className={styles['p-siteControlWidget']}
          ref={pageRef}
          style={{ paddingBottom: '32px' }}
        >
          <Form<FormValues>
            onSubmit={async (values: FormValues) => {
              try {
                const errors = validateFormValues(values);
                if (errors && errors.length > 0) {
                  return { [FORM_ERROR]: errors.join(',') };
                }

                if (id) {
                  await dispatch(
                    updateSiteControlWidget(
                      id,
                      convertFormValuesToSwagger(values, id)
                    )
                  );
                } else {
                  await dispatch(
                    createSiteControlWidget({
                      ...convertFormValuesToSwagger(values, id),
                      supplier_id: activeOrganization?.id ?? '',
                    })
                  );
                  await setNewlyCreatedValues(values);
                }
                if (step === 'WIDGET_SETTINGS') {
                  await history.push(`/sitecontrolwidgets`);
                }
              } catch (err) {
                return { [FORM_ERROR]: t('Save Failed') };
              }
            }}
            initialValues={initialValues}
            mutators={getArrayMutators()}
          >
            {({
              handleSubmit,
              form: { change, submit },
              values,
              submitting,
              submitError,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <section style={{ marginBottom: '16px' }}>
                    <StepsNavigation currentStep={step} steps={steps} />
                  </section>
                  <section
                    className={styles['p-topButtons']}
                    style={{ marginBottom: '16px' }}
                  >
                    {step === 'TEMPLATE_SELECT' && (
                      <Button
                        text={t('Cancel')}
                        onClick={() => {
                          history.push(`/sitecontrolwidgets`);
                        }}
                        color="white"
                        style={{ width: '140px' }}
                      />
                    )}
                    {(step === 'TEMPLATE_EDIT' ||
                      step === 'WIDGET_SETTINGS') && (
                      <Button
                        text={t('Back')}
                        onClick={() => {
                          if (step === 'TEMPLATE_EDIT') {
                            setStep('TEMPLATE_SELECT');
                          }
                          if (step === 'WIDGET_SETTINGS') {
                            setStep('TEMPLATE_EDIT');
                          }
                        }}
                        color="white"
                        style={{ width: '140px' }}
                      />
                    )}
                    {step === 'TEMPLATE_EDIT' && (
                      <Button
                        text={t('Next')}
                        onClick={() => {
                          change('content', values.content);
                          // Do not submit if it's create new
                          if (id && existingSiteControlWidget) {
                            submit();
                          }

                          setStep('WIDGET_SETTINGS');
                        }}
                        style={{ width: '140px' }}
                      />
                    )}
                    {step === 'WIDGET_SETTINGS' && (
                      <Button
                        text={t('Save')}
                        type="submit"
                        loading={submitting}
                        style={{ width: '140px' }}
                      />
                    )}
                  </section>
                  {submitError && (
                    <ul
                      className={baseStyles['u-error-msg']}
                      style={{ marginBottom: '16px' }}
                    >
                      <div>{t('Save Failed')}:</div>
                      {(submitError as string)
                        .split(',')
                        .map((error, index) => (
                          <li
                            key={index}
                            style={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                          >
                            {error}
                          </li>
                        ))}
                    </ul>
                  )}
                  {step === 'TEMPLATE_SELECT' && (
                    <section className={styles['p-section']}>
                      <Field name="content.type">
                        {({ input }: FieldRenderProps<string>) => {
                          return (
                            <ul className={styles['p-board']}>
                              {templateOptions.map((template) => (
                                <li key={template.value}>
                                  <div
                                    style={{
                                      fontWeight: 700,
                                      marginBottom: '16px',
                                      alignSelf: 'flex-start',
                                    }}
                                  >
                                    {template.text}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: '16px',
                                      alignSelf: 'flex-start',
                                    }}
                                  >
                                    {t('Description Text Goes Here. ')}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: '24px',
                                      height: '480px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Widget
                                      data={
                                        existingSiteControlWidget &&
                                        step !== 'TEMPLATE_SELECT'
                                          ? (existingSiteControlWidget as SiteControlWidget)
                                          : (useDefaultSiteControlWidget(
                                              template.value,
                                              t
                                            ) as SiteControlWidget)
                                      }
                                      displayMode={
                                        'TEMPLATE_SELECT' as DisplayMode
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      text={t('Use This Template')}
                                      onClick={async () => {
                                        await handleScrollToTop();
                                        await setStep('TEMPLATE_EDIT');
                                        await input.onChange(template.value);
                                        await change(
                                          'content',
                                          useDefaultContent(template.value, t)
                                        );
                                        // Do not submit if it's create new
                                        if (id && existingSiteControlWidget) {
                                          await submit();
                                        }
                                      }}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          );
                        }}
                      </Field>
                    </section>
                  )}
                  {step === 'TEMPLATE_EDIT' && (
                    <TemplateEdit type={values.content.type} />
                  )}
                  {step === 'WIDGET_SETTINGS' && <WidgetSettings />}
                </form>
              );
            }}
          </Form>
        </div>
      </PageContent>
    </V3Page>
  );
};
