import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  defaultProductCurrencySelector,
  defaultProductLanguageSelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { createProduct, updateProduct } from 'client/actions/products';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { PerParticipantPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerGroupPriceInput';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import { ProductEditBasicPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditBasicPopUp';
import { getArrayMutators } from 'client/libraries/util/form';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  LanguageUppercaseISO,
  uppercaseIsoToLowercaseIso,
} from 'shared/libraries/i18n';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import type {
  Pricing,
  Recurrence,
  StartTime,
  UnitPricing,
} from 'shared/models/swagger';
import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import { ProductTypeInput } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/ProductTypeInput';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Badge } from 'client/components/v3/Common/Badge';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { BasicFormValues } from 'client/pages/v3/Product/ProductEdit/types';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { config } from 'client/config';

const removeUnitPackageMappings = (units: UnitPricing[]) => {
  for (const unit of units) {
    if (unit.guest_type != null) {
      unit.guest_type.package_component_guest_type_mappings = [];
    }
  }
};

const removeStartTimePackageMappings = (startTimes: StartTime[]) => {
  for (const startTime of startTimes) {
    startTime.package_component_time_slots = [];
  }
};

const focusOnError = createDecorator();

type Props = {
  copy?: boolean;
  sourceProductId?: string | null;
  onSubmitSuccess: (arg0: string) => void;
  onSubmitFailed: () => void;
  onValidationError: () => void;
  setCurrentSectionId: Dispatch<SetStateAction<string>>;
};

export const BasicInformationEditor = ({
  copy,
  sourceProductId,
  onSubmitSuccess,
  onSubmitFailed,
  onValidationError,
  setCurrentSectionId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { required } = getValidators(t);

  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = useState<boolean>(false);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);

  const editingProduct = useContext(EditingProductContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const sourceLanguageUppercaseIso =
    editingProduct?.source_language ?? defaultLanguage;
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];

  const copyCreateRequired = copy && sourceProductId === editingProduct?.id;

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  const getDefaultPrices = (languageLowercaseIso: string) => {
    return [
      {
        method: 'PER_PARTICIPANT' as const,
        unit: t('Adult', { lng: languageLowercaseIso }),
        ageBandMinimum: 13,
        ageBandMaximum: 0,
        price: '100',
      },
      {
        method: 'PER_PARTICIPANT' as const,
        unit: t('Child', { lng: languageLowercaseIso }),
        ageBandMinimum: 4,
        ageBandMaximum: 12,
        price: '70',
      },
      {
        method: 'PER_PARTICIPANT' as const,
        unit: t('Infant', { lng: languageLowercaseIso }),
        ageBandMinimum: 0,
        ageBandMaximum: 3,
        price: '0',
      },
    ];
  };

  const initialValues = useMemo(() => {
    const defaultPriceMethod = 'PER_PARTICIPANT';

    const priceMethod =
      (editingProduct?.pricing &&
        editingProduct?.pricing.length > 0 &&
        editingProduct?.pricing.slice(-1)[0].units && // Note: get last setting for product copy.
        editingProduct?.pricing.slice(-1)[0].units.length > 0 &&
        editingProduct?.pricing.slice(-1)[0].units[0].method) ||
      defaultPriceMethod;

    const prices =
      editingProduct?.pricing && editingProduct?.pricing.length > 0
        ? (editingProduct?.pricing.slice(-1)[0].units || []).map((unit) => ({
            method: unit.method,
            unit: unit.guest_type?.key,
            price: unit.gross && unit.gross?.substring(3),
            ageBandMinimum: unit.guest_type?.minimum_age,
            ageBandMaximum: unit.guest_type?.maximum_age || 0,
            perBookingUnits: unit.per_booking_guest_types?.map(
              (perBookingGuestType) => ({
                unit: perBookingGuestType?.key,
                title: perBookingGuestType?.title,
                ageBandMinimum: perBookingGuestType?.minimum_age,
                ageBandMaximum: perBookingGuestType?.maximum_age || 0,
              })
            ),
          }))
        : getDefaultPrices(sourceLanguageLowercaseIso);

    const perGroupPrices =
      editingProduct?.pricing && editingProduct?.pricing.length > 0
        ? (editingProduct?.pricing.slice(-1)[0].units || [])
            .filter((unit) => unit.method === 'PER_GROUP')
            .map((unit) => ({
              method: unit.method,
              title: unit.title,
              groupUnits: unit.group_guest_types?.map((type) => {
                return type.key;
              }),
              price: unit.gross && unit.gross?.substring(3),
            }))
        : [];

    let productType = 'NORMAL';
    const packageProductIds =
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? [];
    const passthroughProductId =
      editingProduct?.shared_allotment_references
        ?.passthrough_base_product_id ?? '';

    if (packageProductIds.length > 0) {
      productType = 'PACKAGE';
    }
    if (passthroughProductId) {
      productType = 'PASSTHROUGH';
    }

    let productCode = editingProduct?.supplier_reference;
    let internalProductName = editingProduct?.internal_product_name;
    if (copyCreateRequired) {
      productCode = '';
      internalProductName = '';
    }
    if (isTutorialActive && copyCreateRequired) {
      productCode = `[COPY] ${editingProduct?.supplier_reference ?? ''}`;
      internalProductName = `[COPY] ${
        editingProduct?.internal_product_name ?? ''
      }`;
    }
    const shouldShowPricingOnBwCalendar =
      editingProduct?.should_show_pricing_on_bw_calendar?.value ?? false;

    return {
      productCode: productCode,
      productName:
        (copyCreateRequired ? '[COPY] ' : '') +
        (editingProduct?.product_name || ''),
      internalProductName: internalProductName,
      productDescription: editingProduct?.description,
      mediaUrls: (editingProduct?.media || []).map(
        (mediaItem) => mediaItem.url
      ),
      prices,
      perGroupPrices,
      priceMethod,
      sourceLanguage: sourceLanguageUppercaseIso,
      productType,
      packageProductIds,
      passthroughProductId,
      shouldShowPricingOnBwCalendar,
    };
  }, [
    editingProduct,
    sourceLanguageUppercaseIso,
    sourceLanguageLowercaseIso,
    copyCreateRequired,
  ]);

  return (
    <>
      <Form
        onSubmit={async (values: BasicFormValues) => {
          try {
            // If copying an existing product
            if (editingProduct && !copyCreateRequired) {
              await dispatch(
                updateProduct(editingProduct.id, {
                  shared_allotment_references: {
                    package_component_product_ids:
                      values.packageProductIds?.filter((id) => Boolean(id)),
                    passthrough_base_product_id: values.passthroughProductId,
                  },
                  supplier_reference: values.productCode,
                  product_name: values.productName,
                  internal_product_name: values.internalProductName,
                  description: values.productDescription,
                  media: convertMediaUrlsToProductMediaItems(
                    values.mediaUrls || []
                  ),
                  pricing: [
                    {
                      days_of_week: [
                        'SUN',
                        'MON',
                        'TUE',
                        'WED',
                        'THU',
                        'FRI',
                        'SAT',
                      ],
                      units: [
                        ...(values.prices || []).map((price) => {
                          let guestType;
                          if (price.unit) {
                            guestType = {
                              title: price.unit,
                              key: price.unit,
                              minimum_age: price.ageBandMinimum,
                              maximum_age: price.ageBandMaximum,
                            };
                          }

                          return {
                            method: price.method,
                            guest_type: guestType,
                            per_booking_guest_types: price.perBookingUnits?.map(
                              (perBookingUnit) => ({
                                key: perBookingUnit.unit,
                                title: perBookingUnit.title,
                                minimum_age: perBookingUnit.ageBandMinimum,
                                maximum_age: perBookingUnit.ageBandMaximum,
                              })
                            ),
                            gross: `${defaultCurrency}${price.price}`,
                            net: `${defaultCurrency}${price.price}`,
                          };
                        }),
                        ...(values.perGroupPrices ?? []).map((price) => ({
                          method: 'PER_GROUP' as const,
                          title: price.title,
                          group_guest_types: (price.groupUnits ?? []).map(
                            (groupUnit) => ({
                              title: groupUnit,
                              key: groupUnit,
                            })
                          ),
                          gross: `${defaultCurrency}${price.price ?? 0}`,
                          net: `${defaultCurrency}${price.price ?? 0}`,
                        })),
                      ],
                    },
                  ],
                  should_show_pricing_on_bw_calendar: {
                    value: values.shouldShowPricingOnBwCalendar,
                  },
                })
              );
              await onSubmitSuccess(editingProduct.id);
            }
            // If creating a new product
            else {
              const result: any = await dispatch(
                createProduct({
                  shared_allotment_references: {
                    package_component_product_ids:
                      values.packageProductIds?.filter((id) => Boolean(id)),
                    passthrough_base_product_id: values.passthroughProductId,
                  },
                  is_tutorial_product: false,
                  start_timezone: defaultTimezone,
                  supplier_reference: values.productCode,
                  internal_product_name: values.internalProductName,
                  source_language: values.sourceLanguage,
                  product_name: values.productName,
                  description: values.productDescription,
                  media: convertMediaUrlsToProductMediaItems(
                    values.mediaUrls || []
                  ),
                  pricing: [
                    {
                      days_of_week: [
                        'SUN',
                        'MON',
                        'TUE',
                        'WED',
                        'THU',
                        'FRI',
                        'SAT',
                      ],
                      units: [
                        ...(values.prices || []).map((price) => {
                          let guestType;
                          if (price.unit) {
                            guestType = {
                              title: price.unit,
                              key: price.unit,
                              minimum_age: price.ageBandMinimum,
                              maximum_age: price.ageBandMaximum,
                            };
                          }

                          return {
                            method: price.method,
                            guest_type: guestType,
                            gross: `${defaultCurrency}${price.price}`,
                            net: `${defaultCurrency}${price.price}`,
                            per_booking_guest_types: price.perBookingUnits?.map(
                              (perBookingUnit) => ({
                                key: perBookingUnit.unit,
                                title: perBookingUnit.title,
                                minimum_age: perBookingUnit.ageBandMinimum,
                                maximum_age: perBookingUnit.ageBandMaximum,
                              })
                            ),
                          };
                        }),
                        ...(values.perGroupPrices ?? []).map((price) => ({
                          method: 'PER_GROUP' as const,
                          title: price.title,
                          group_guest_types: (price.groupUnits ?? []).map(
                            (groupUnit) => ({
                              title: groupUnit,
                              key: groupUnit,
                            })
                          ),
                          gross: `${defaultCurrency}${price.price ?? 0}`,
                          net: `${defaultCurrency}${price.price ?? 0}`,
                        })),
                      ],
                    },
                  ],
                  should_show_pricing_on_bw_calendar: {
                    value: values.shouldShowPricingOnBwCalendar,
                  },
                })
              );
              // Note: createProduct() supports a subset of all products field
              //       so we need to call updateProduct() to copy all fields
              if (copyCreateRequired && result) {
                const productPatch = {
                  ...editingProduct,
                  ...{
                    add_ons: editingProduct?.add_ons?.map((add_on) => {
                      return {
                        ...add_on,
                        ...{
                          pricing: add_on.pricing?.map((price) => {
                            return {
                              ...price,
                              net: price.gross,
                            };
                          }),
                        },
                      };
                    }),
                    transportations: editingProduct?.transportations?.map(
                      (transportation) => {
                        return {
                          ...transportation,
                          ...{
                            pricing: transportation.pricing?.map((price) => {
                              return {
                                ...price,
                                net: price.gross,
                              };
                            }),
                          },
                        };
                      }
                    ),
                  },
                  ...{
                    id: result.id,
                    pricing: result.pricing,
                    product_name: result.product_name,
                    internal_product_name: result.internal_product_name,
                    description: result.description,
                    source_language: result.source_language,
                    supplier_id: result.supplier_id,
                    supplier_reference: result.supplier_reference,
                    emergency_contact: result.emergency_contact,
                    start_timezone: result.start_timezone,
                    gateway_name: result.gateway_name,
                    booking_widget_settings: result.booking_widget_settings,
                    media: result.media,
                    shared_allotment_references:
                      result.shared_allotment_references,
                  },
                };
                productPatch?.pricing?.forEach((priceSchedule: Pricing) => {
                  removeUnitPackageMappings(priceSchedule.units || []);
                });
                productPatch?.recurrence?.forEach(
                  (recurrenceRule: Recurrence) =>
                    removeStartTimePackageMappings(
                      recurrenceRule.start_times || []
                    )
                );

                await dispatch(updateProduct(result.id, productPatch));
              }
              await onSubmitSuccess(result.id);
            }
          } catch (err) {
            await onSubmitFailed();
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        validate={(values) => {
          const errors: Record<string, string> = {};

          if (
            !values.productCode ||
            !values.productName ||
            !values.internalProductName
          ) {
            onValidationError();
          }

          return errors;
        }}
        // Need to disable this in tsx
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitError, values, form: { change } }) => {
          const { setBasicFormValues } = useContext(ProductHelperContext);
          setBasicFormValues(values);

          return (
            <>
              <Snackbar
                text={t('Save Failed')}
                color="error"
                shouldShow={submitError}
              />
              <form
                id="createProductBasicInformationForm"
                onSubmit={handleSubmit}
              >
                <section id="basic" className={styles['g-section']}>
                  <p className={styles['p-products__ttl']}>
                    {t('Basic Information')}
                  </p>
                  <p className={baseStyles['u-mt-2']}>
                    {t('Set up basic product information')}
                  </p>
                  <CollapsibleSection
                    id="products"
                    title={t('Products')}
                    setCurrentSectionId={setCurrentSectionId}
                  >
                    <ul className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Product Code')}</div>
                            <Badge
                              size="sm"
                              label={t('Required')}
                              color="warning-contained"
                            />
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name="productCode" validate={required}>
                            {({ input, meta: { error, touched } }) =>
                              editingProduct && !copyCreateRequired ? (
                                input.value
                              ) : (
                                <TextField
                                  {...input}
                                  type="text"
                                  error={touched && error ? error : undefined}
                                />
                              )
                            }
                          </Field>
                        </div>
                      </li>
                      {!isTutorialActive && (
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <div>{t('Language')}</div>
                              <Badge
                                size="sm"
                                label={t('Required')}
                                color="warning-contained"
                              />
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            {editingProduct != null ? (
                              values?.sourceLanguage === 'EN_US' ? (
                                t('English')
                              ) : (
                                t('Japanese')
                              )
                            ) : (
                              <EnumRadioButtonGroup
                                name="sourceLanguage"
                                options={[
                                  {
                                    value: 'JA_JP',
                                    label: t('Japanese'),
                                  },
                                  {
                                    value: 'EN_US',
                                    label: t('English'),
                                  },
                                ]}
                                onChange={(newValue) => {
                                  change(
                                    'prices',
                                    getDefaultPrices(
                                      uppercaseIsoToLowercaseIso[
                                        newValue as LanguageUppercaseISO
                                      ]
                                    )
                                  );
                                }}
                              />
                            )}
                          </div>
                        </li>
                      )}
                      {!isTutorialActive &&
                        config.enableShowPricingOnBwCalendar && (
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <div>{t('Booking Site Calendar Display')}</div>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <Field name="shouldShowPricingOnBwCalendar">
                                {({ input }) => (
                                  <Toggle
                                    checked={input.value}
                                    onChange={() =>
                                      input.onChange(!input.value)
                                    }
                                    label={
                                      <div>
                                        <div>
                                          {t(
                                            'Display unit price on booking site calendar'
                                          )}
                                        </div>
                                      </div>
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                          </li>
                        )}
                      {!isTutorialActive && (
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <div>{t('Type')}</div>
                              <Badge
                                size="sm"
                                label={t('Required')}
                                color="warning-contained"
                              />
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <ProductTypeInput />
                          </div>
                        </li>
                      )}
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Internal Product Name')}</div>
                            <Badge
                              size="sm"
                              label={t('Required')}
                              color="warning-contained"
                            />
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name="internalProductName" validate={required}>
                            {({ input, meta: { error, touched } }) => (
                              <TextField
                                {...input}
                                type="text"
                                error={touched && error ? error : undefined}
                              />
                            )}
                          </Field>
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Product Name')}</div>
                            <Badge
                              size="sm"
                              label={t('Required')}
                              color="warning-contained"
                            />
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name="productName" validate={required}>
                            {({ input, meta: { error, touched } }) => (
                              <TextField
                                {...input}
                                type="text"
                                error={touched && error ? error : undefined}
                              />
                            )}
                          </Field>
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Product Description')}</div>
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name="productDescription">
                            {({ input, meta: { error, touched } }) => (
                              <TextArea
                                {...input}
                                showCharacterCount
                                height={120}
                                error={touched && error}
                                fullContainerWidth
                              ></TextArea>
                            )}
                          </Field>
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Images/Videos')}</div>
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <div
                            className={
                              styles['p-products__section__body__item']
                            }
                          >
                            <Field name="mediaUrls">
                              {({ input }) => (
                                <ImageVideoAudioInput
                                  fileUrls={input.value || []}
                                  onChange={(newValue) =>
                                    input.onChange(newValue)
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </CollapsibleSection>

                  <CollapsibleSection
                    id="prices"
                    title={t('Prices')}
                    setCurrentSectionId={setCurrentSectionId}
                  >
                    <ul className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <div>{t('Prices')}</div>
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <EnumRadioButtonGroup
                            name="price"
                            options={[
                              {
                                value: 'PER_PARTICIPANT',
                                label: t('Per-participant'),
                                tooltipText: t(
                                  'Price is set per unit (1 person, etc.). Example: adults, children, toddlers, visitors, etc.'
                                ),
                              },
                              {
                                value: 'PER_BOOKING',
                                label: t('Per-booking'),
                                tooltipText: t(
                                  'Price is set once per booking regardless of the number of people or units. Example: 1 unit, 1 group, 1 time'
                                ),
                              },
                            ]}
                            onChange={(val) => {
                              change(
                                'priceMethod',
                                val as 'PER_PARTICIPANT' | 'PER_BOOKING'
                              );
                              if (val === 'PER_PARTICIPANT') {
                                change(
                                  'prices',
                                  getDefaultPrices(values?.sourceLanguage)
                                );
                              } else {
                                change('prices', [
                                  {
                                    method: 'PER_BOOKING',
                                    price: '100',
                                  },
                                ]);
                              }
                            }}
                            defaultChecked={
                              values?.priceMethod ?? 'PER_PARTICIPANT'
                            }
                          />
                        </div>
                        {values?.priceMethod === 'PER_PARTICIPANT' && (
                          <>
                            <PerParticipantPriceInput
                              name="prices"
                              language={
                                uppercaseIsoToLowercaseIso[
                                  values?.sourceLanguage
                                ]
                              }
                              currency={defaultCurrency}
                              showCustom={sourceProductId ? true : false}
                            />
                            {sourceProductId && (
                              <PerGroupPriceInput
                                name={'perGroupPrices'}
                                currency={defaultCurrency}
                                showNet={false}
                              />
                            )}
                          </>
                        )}
                        {values?.priceMethod === 'PER_BOOKING' && (
                          <PerBookingPriceInput
                            name="prices"
                            currency={defaultCurrency}
                          />
                        )}
                        <p
                          className={clsx(
                            styles['c-table-list__note'],
                            baseStyles['u-mt-4']
                          )}
                        >
                          {t(
                            '* If prices differ for certain days of the week or time periods, you can make these changes later.'
                          )}
                        </p>
                      </li>
                    </ul>
                  </CollapsibleSection>
                </section>
                {isTutorialActive && showHelpPopUp && (
                  <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
                )}
              </form>
            </>
          );
        }}
      </Form>
      {isTutorialActive && showPopUp && (
        <ProductEditBasicPopUp popUpDisable={() => setShowPopUp(false)} />
      )}
    </>
  );
};
