import { config } from 'client/config';
import { Organization, Reservation } from 'shared/models/swagger';

const partnershipSupplierIds = [
  // Dev
  '64b658b8-8839-580b-845d-f3e21e7a6605', // Goro Dev 6
  '0e1860db-74b7-5f78-8254-983f37dc600f', // Goro Dev 10
  '54698d99-90ac-5b53-8dc7-0e1838cd733e', // michi partner-src
  '06fd1da9-4f14-5feb-af8b-09809dd9ce30', // GMO Supper
  // Stg
  '0fb5f4c0-6ae3-5593-b648-a77444c1ee80', // Partnership Stg supplier
  '36b717ce-520d-558b-8263-8233681b1198', // Partnership Stg supplier 2
  '345295c2-31a2-5bc8-a3e2-af9dfdefd571', // 城島高原パーク
  'ccee58f4-36bf-5b4f-9b24-14b54df4feab', // シギラセブンマイルズリゾート体験施設部門
  '947ca2ff-3fda-5891-a09b-20dbf9e65a72', // 地熱観光ラボ縁間DEMO
  '613addb3-a048-55e8-ab7e-a4f3ba5faa67', // Waikiki Trolley
  '951916f3-82d9-5aa2-926e-4c88fa85edcc', // 八重山観光フェリー
  'ae8cefc8-0a34-598c-858e-d3a774ac4c7f', // おきなわワールドDEMO
  '3c74dc83-7b0e-5f18-9aab-48106f1aae55', // DEMOガンガラーの谷
  // Prod
  '39cae3b3-c882-59c7-84cf-ee80f62e4a3e', // 成田ゆめ牧場
  'cc447626-09c3-5eee-ba68-538ad8189331', // Nutmeg動作確認(GMO)
  'f827ea67-f933-5bd8-b8e8-02a438ed9cfa', // すみだ水族館
  'cd15eb52-2b2a-50d7-a275-e9fe51011078', // スカイバス東京
  '702c368c-fd67-5e3a-a096-53acf9119b5e', // 【シギラセブンマイルズリゾート】 乗り物・体験・観光予約サイト
];

const partnershipAgentIds = [
  // Dev
  '3fb4e89c-bfbb-5783-8231-97fc03f5ac70', // Goro Dev 9
  '38014894-326f-552a-a028-175fe7698b14', // Goro Dev 11
  '4236d75c-5ed6-59aa-91ed-bd8dc38cc2be', // michi partner-agt
  '1ee710aa-6977-5673-b431-607ca8c66b3c', // michi dev 19 (GMO)
  // Stg
  '59ee3d7b-a803-5a16-a0d2-868166b4645f', // Nutmeg Travel
  'ba00bb10-22d6-53c2-95f3-21cebb3d0fa9', // Partnership Stg agent
  '3a9fb26b-cb0a-5bd5-9216-7bbd72f7fafa', // 南西楽園リゾートDEMO
  '31fafcc1-10cc-563b-895b-30551333fb95', // 亀の井バスDEMO
  '714fa7e0-47dd-54b4-8633-0e424792a356', // Enoa Sales Associate - 1
  'a3867e4c-5d7f-51ac-91ae-1ed87a1c63d1', // ナツメグ_石垣島MAP
  '3c74dc83-7b0e-5f18-9aab-48106f1aae55', // DEMOガンガラーの谷
  'ae8cefc8-0a34-598c-858e-d3a774ac4c7f', // おきなわワールドDEMO
  // Prod
  '4d357980-ad7d-5111-8342-657d1b079935', // Plus Narita Lab, Inc.
  '0635f802-a259-5b0b-b2ee-77e2e48cc8cc', // ミチヒロテスト 01
  'cd8bf376-7159-5ed1-a75a-78e6bafffb31', // Nutmeg Travel
  'fe6acc42-0afa-513b-9f48-8edf6ecdc592', // シギラセブンマイルズリゾート
];

export const isPartnershipSupplier = (org: Organization | null) => {
  if (!org) {
    return false;
  }

  if (partnershipSupplierIds.includes(org.id)) {
    return true;
  }

  return false;
};

export const isPartnershipAgent = (org: Organization | null) => {
  if (!org) {
    return false;
  }

  if (partnershipAgentIds.includes(org.id)) {
    return true;
  }

  return false;
};

export const isPartnershipComponentReservation = (reservation: Reservation) => {
  if (!config.enablePartnershipPackageProduct) {
    return false;
  }

  return (
    reservation.is_partnership_reservation && reservation.package_reservation_id
  );
};
