import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Field,
  FieldRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';
import _ from 'lodash';
import { useCallback, useState } from 'react';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { Badge } from 'client/components/v3/Common/Badge';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Button } from 'client/components/v3/Common/Button';

import styles from '../SiteControlWidgetEditor.module.css';
import { TargetListEditor } from '../TargetListEditor';
import { FormValues } from '../formValues';
import { ScheduleEditor } from '../ScheduleEditor';

export const WidgetSettings = () => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const form = useForm();
  const { values } = useFormState<FormValues>();

  const [useStopDisplay, setUseStopDisplay] = useState(
    Boolean(values.stopDisplayTiming)
  );

  const timingOptions = [
    {
      text: t('Immediately'),
      value: 'IMMEDIATELY',
    },
    {
      text: t('Scroll depth'),
      value: 'SCROLL_DEPTH',
    },
    {
      text: t('Time on page'),
      value: 'TIME_ON_PAGE',
    },
    {
      text: t('Time on website'),
      value: 'TIME_ON_WEBSITE',
    },
  ];
  const stopDisplayTimingOptions = [
    {
      text: t('Forever after user closed widget'),
      value: 'FOREVER',
    },
    // TODO: for now it's only X_DAYS but X_HOURS etc might be needed in the future
    {
      text: t('For days after user closed widget'),
      value: 'X_DAYS',
    },
  ];

  const mql = window.matchMedia('(max-width: 768px)');

  const validateBaseUrl = useCallback(
    (url?: string): string | undefined => {
      if (!url) {
        return t('Required');
      }

      // Check for proper protocol format
      if (!url.match(/^(http|https):\/\/.+/)) {
        return t('URL is incorrect');
      }

      try {
        const urlObject = new URL(url);
        if (
          !urlObject.hostname ||
          !urlObject.hostname.includes('.') ||
          urlObject.hostname.endsWith('.')
        ) {
          return t('URL is incorrect');
        }
        if (urlObject.pathname !== '/') {
          return t(
            'Please enter only the domain (example.com, example.co.jp, etc.) as base URL'
          );
        }
      } catch {
        return t('URL is incorrect');
      }
    },
    [t]
  );

  return (
    <section className={styles['p-section']}>
      <ul className={styles['p-list']}>
        <li className={styles['p-list__item']}>
          <div
            className={clsx(
              styles['p-list__item__ttl2'],
              styles['p-list__item__ttl__withBadge']
            )}
          >
            <p style={{ marginRight: '6px' }}>{t('Name')}</p>
            <div>
              <Badge
                size="sm"
                label={t('Required')}
                color="warning-contained"
              />
            </div>
          </div>
          <div className={styles['p-list__item__body2']}>
            <Field name="name" validate={required}>
              {({
                input,
                meta: { error, touched },
              }: FieldRenderProps<string>) => (
                <TextField
                  {...input}
                  type="text"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </li>
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl2']}>
            <p>{t('Status')}</p>
          </div>
          <div className={styles['p-list__item__body2']}>
            <EnumRadioButtonGroup
              name="status"
              options={[
                {
                  value: 'DRAFT',
                  label: t('Draft'),
                },
                {
                  value: 'ACTIVE',
                  label: t('Active'),
                },
              ]}
              onChange={(newValue) => {
                form.change('status', newValue as 'DRAFT' | 'ACTIVE');
              }}
            />
          </div>
        </li>
        <li className={clsx(styles['p-list__item'], styles['column'])}>
          <div style={{ marginBottom: '16px' }}>
            <p>{t('Display widget on')}</p>
          </div>
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '16px' }}
          >
            <div
              className={clsx(
                styles['p-list__item__ttl2'],
                styles['p-list__item__ttl__withBadge']
              )}
            >
              <p style={{ marginRight: '6px' }}>{t('Base URL')}</p>
              <div>
                <Badge
                  size="sm"
                  label={t('Required')}
                  color="warning-contained"
                />
              </div>
            </div>
            <div className={styles['p-list__item__body2']}>
              <Field name="baseUrl" validate={validateBaseUrl}>
                {({
                  input,
                  meta: { error, touched },
                }: FieldRenderProps<string>) => (
                  <TextField
                    {...input}
                    type="text"
                    error={touched && error ? error : undefined}
                  />
                )}
              </Field>
            </div>
          </div>
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '16px' }}
          >
            <div className={styles['p-list__item__ttl2']}>
              <p>{t('Include')}</p>
            </div>
            <div className={styles['p-list__item__body2']}>
              <TargetListEditor
                name="includes"
                addButtonText={t('Add URL')}
                fieldDisabled={true}
                onClickOutsideModal={() => {
                  if (values.includes && values.includes.length > 0) {
                    // Remove includes with empty path
                    const filteredIncludes = values.includes.filter(
                      (include) => include.path !== ''
                    );
                    form.change(`includes`, filteredIncludes);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles['p-list__item__group']}>
            <div className={styles['p-list__item__ttl2']}>
              <p>{t('Exclude')}</p>
            </div>
            <div className={styles['p-list__item__body2']}>
              <TargetListEditor
                name="excludes"
                addButtonText={t('Add URL')}
                fieldDisabled={true}
                onClickOutsideModal={() => {
                  if (values.excludes && values.excludes.length > 0) {
                    // Remove excludes with empty path
                    const filteredExcludes = values.excludes.filter(
                      (exclude) => exclude.path !== ''
                    );
                    form.change(`excludes`, filteredExcludes);
                  }
                }}
              />
            </div>
          </div>
        </li>
        <li className={clsx(styles['p-list__item'], styles['column'])}>
          <div style={{ marginBottom: '16px' }}>
            <p>{t('Display settings')}</p>
          </div>
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '18px' }}
          >
            <div className={styles['p-list__item__ttl2']}>
              <p>{t('Start displaying widget')}</p>
            </div>
            <div className={styles['p-list__item__body2']}>
              <Field name="timing.type">
                {({ input }: FieldRenderProps<string>) => (
                  <SingleDropdown
                    options={timingOptions}
                    selectedOption={input.value ?? 'IMMEDIATELY'}
                    onChange={(value) => {
                      input.onChange(value);
                      if (value === 'IMMEDIATELY') {
                        form.change('timing.scrollPercentage', undefined);
                        form.change('timing.delayTime', undefined);
                      } else if (value === 'SCROLL_DEPTH') {
                        form.change('timing.delayTime', undefined);
                      } else if (
                        value === 'TIME_ON_PAGE' ||
                        value === 'TIME_ON_WEBSITE'
                      ) {
                        form.change('timing.scrollPercentage', undefined);
                      }
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
          {values.timing.type === 'SCROLL_DEPTH' && (
            <div
              className={styles['p-list__item__group']}
              style={{ marginBottom: '16px' }}
            >
              <div className={styles['p-list__item__ttl2']}>
                <p>{t('Scroll depth')}</p>
              </div>
              <div
                className={styles['p-list__item__body2']}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Field name="timing.scrollPercentage">
                  {({ input }: FieldRenderProps<string>) => (
                    <div style={{ width: mql.matches ? '50%' : '25%' }}>
                      <TextField {...input} type="number" />
                    </div>
                  )}
                </Field>
                <p style={{ marginLeft: '8px' }}>%</p>
              </div>
            </div>
          )}
          {(values.timing.type === 'TIME_ON_PAGE' ||
            values.timing.type === 'TIME_ON_WEBSITE') && (
            <div
              className={styles['p-list__item__group']}
              style={{ marginBottom: '16px' }}
            >
              <div className={styles['p-list__item__ttl2']}>
                <p>{t('Delay time')}</p>
              </div>
              <div
                className={styles['p-list__item__body2']}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Field name="timing.delayTime">
                  {({ input }: FieldRenderProps<string>) => (
                    <div style={{ width: mql.matches ? '50%' : '25%' }}>
                      <TextField {...input} type="number" />
                    </div>
                  )}
                </Field>
                <p style={{ marginLeft: '8px' }}>{t('seconds')}</p>
              </div>
            </div>
          )}
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '16px' }}
          >
            <div className={styles['p-list__item__ttl2']}>
              <p>{t('Schedule')}</p>
            </div>
            <div
              className={clsx(
                styles['p-list__item__body2'],
                styles['p-schedule']
              )}
            >
              <ScheduleEditor />
            </div>
          </div>
          <div className={styles['p-list__item__group']}>
            <div className={styles['p-list__item__ttl2']}>
              <p>{t('Stop displaying widget')}</p>
            </div>
            {!useStopDisplay && (
              <div>
                <a
                  className={styles['p-button__addCondition']}
                  onClick={() => {
                    setUseStopDisplay(true);
                    // Also set form values
                    form.change('stopDisplayTiming', { type: 'FOREVER' });
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Condition')}
                </a>
              </div>
            )}
            {useStopDisplay && (
              <div
                className={clsx(
                  styles['p-list__item__body2'],
                  styles['p-stopDisplay']
                )}
              >
                <div
                  className={
                    values.stopDisplayTiming?.type === 'X_DAYS'
                      ? styles['p-stopDisplay__sixty']
                      : styles['p-stopDisplay__full']
                  }
                >
                  <Field name="stopDisplayTiming.type">
                    {({ input }: FieldRenderProps<string>) => (
                      <SingleDropdown
                        options={stopDisplayTimingOptions}
                        selectedOption={input.value ?? 'FOREVER'}
                        onChange={(value) => {
                          input.onChange(value);
                          if (value === 'FOREVER') {
                            form.change(
                              'stopDisplayTiming.numberOfDays',
                              undefined
                            );
                          } else if (value === 'X_DAYS') {
                            form.change('stopDisplayTiming.numberOfDays', '1');
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>
                {values.stopDisplayTiming?.type === 'X_DAYS' && (
                  <>
                    <Field name={`stopDisplayTiming.numberOfDays`}>
                      {({ input }: FieldRenderProps<string>) => (
                        <>
                          <div className={styles['p-stopDisplay__twenty']}>
                            <SingleDropdown
                              options={_.times(366, (days) => ({
                                value: (days + 1).toString(),
                                text: (days + 1).toString(),
                              }))}
                              selectedOption={input.value.toString() || '0'}
                              onChange={input.onChange}
                            />
                          </div>
                          <div className={styles['p-stopDisplay__twenty']}>
                            <SingleDropdown
                              options={[
                                {
                                  value: '',
                                  text: t(
                                    input.value.toString() === '0'
                                      ? 'day'
                                      : 'days'
                                  ),
                                },
                              ]}
                              selectedOption=""
                              onChange={() => {
                                //
                              }}
                              inputStyle={{ width: '50px' }}
                            />
                          </div>
                        </>
                      )}
                    </Field>
                  </>
                )}
                <div className={styles['p-button__deleteCondition']}>
                  <Button
                    size="icon"
                    color="tertiarygray"
                    onClick={() => {
                      setUseStopDisplay(false);
                      // Also reset form values
                      form.change('stopDisplayTiming', undefined);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </li>
      </ul>
    </section>
  );
};
