import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import {
  DigitalMapParams,
  DigitalMap,
  SourceLanguage,
} from 'shared/models/swagger';
import type { Weekday } from 'client/libraries/util/weekdays';
import { config } from 'client/config';

export type MenuItem =
  | 'MAP'
  | 'MY_ACCOUNT'
  | 'TICKETS'
  | 'SCHEDULE'
  | 'STAMP_RALLY'
  | 'ITINERARIES';

export type CustomMenuItem = {
  label: string;
  url: string;
  itemType:
    | 'CUSTOM_LINK'
    | 'CUSTOM_SUBMENU'
    | 'MAP'
    | 'MY_ACCOUNT'
    | 'TICKETS'
    | 'SCHEDULE'
    | 'STAMP_RALLY'
    | 'ITINERARIES';
  subMenuItems: CustomMenuItem[];
  id: string;
};

export interface FormValues {
  name: string;
  useOverlayImage: boolean;
  useOverlayTiling: boolean;
  overlays: Overlay[];
  pins: Pin[];
  pinCategories: PinCategory[];
  structuredInfoCategories: PinCategory[];
  editingMode: 'NORMAL' | 'TOP_LEFT' | 'ZOOM' | 'CENTER';
  showOverlayOnMap: boolean;
  stampRallyId: string;
  mapRotation: number;
  mapZoom: number;
  mapCenter: Location;
  version: 'V1' | 'V2';
  productIds: string[];
  ga4AnalyticsTag: string;
  disableIncludingMapLinkInCustomerEmails: boolean;
  seoParams: SeoParams;
  floorCount: number;
  menuItems: MenuItem[];
  customMenuItems: CustomMenuItem[];
  showLanguageMenu: boolean;
  languageMenuItems: {
    language: SourceLanguage;
    mapId: string;
  }[];
  showUberButtonForPins: boolean;
  showQrCodeButton: boolean;
  enableHighResolutionTiles: boolean;
  showChatbotButton: boolean;
  showBusRoutes: boolean;
  busRoutes: {
    path: string;
  }[];
  customGoogleMapId: string;
  showRecommendedItinerary: boolean;
  recommendedItineraryUrls: string[];
}

export interface SeoParams {
  title: string;
  metaDescription: string;
  thumbnailUrl: string;
  customDomain: string;
  language: SourceLanguage;
}

export interface Overlay {
  floorNumber: number;
  imageUrl: string;
  topLeft: Location;
  widthInMeters: number;
  aspectRatio: {
    width: number;
    height: number;
  };
}

export interface PinCategory {
  label: string;
  iconUrl: string;
  initiallySelected?: boolean;
}

export enum PinInteractionType {
  NONE = 'NONE',
  BOOKABLE_PRODUCT = 'BOOKABLE_PRODUCT',
  RESTAURANT = 'RESTAURANT',
  CUSTOM = 'CUSTOM',
}

export enum PinRestaurantType {
  ONLINE_ORDER = 'ONLINE_ORDER',
  OPEN_TABLE = 'OPEN_TABLE',
}

export interface StructuredInfoItem {
  infoCategoryKey: string;
  values: string[];
}

export interface Pin {
  title: string;
  description: string;
  iconUrl: string;
  iconFit: 'COVER' | 'CONTAIN';
  useThumbnailImagePinMarker: boolean;
  tags: string[];
  structuredInfoItems: StructuredInfoItem[];
  thumbnailUrl: string;
  location: Location;
  category?: string;
  showWaitTime: boolean;
  waitTime: number;
  timeDataType: 'NONE' | 'SHOWTIMES' | 'TIME_RANGE';
  openFrom: string;
  openTo: string;
  showtimes: string[];
  openHourSchedules: OpenHourSchedule[];
  showtimeSchedules: ShowtimeSchedule[];
  mediaUrls: string[];
  associatedProductIds: string[];
  couponIds: string[];
  shouldUseStampRally: boolean;
  stampRallyLabel: string;
  stampImageUrl: string;
  activationDistance: number;
  showFreeFormDescription: boolean;
  freeFormDescription: string;
  pinInteractionType: PinInteractionType;
  pinRestaurantType: PinRestaurantType;
  onlineOrderRestaurantId: string;
  openTableRestaurantId: string;
  key: string;
  floorNumber: number;
  genericActionButton: {
    buttonText: string;
    destinationUrl: string;
    destinationDisplay: 'IFRAME' | 'SAME_TAB' | 'NEW_TAB';
  };
}
export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};

export interface OpenHourSchedule {
  openFrom: string;
  openTo: string;
  schedule: Schedule;
}

export interface ShowtimeSchedule {
  showtimes: string[];
  schedule: Schedule;
}

export interface Schedule {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
}

export interface Location {
  latitude: number;
  longitude: number;
}

const parse = (value: string | null | typeof undefined) => {
  if (!value) {
    return {};
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    /* empty */
  }

  return {};
};

export const convertFormValuesToSwagger = (
  values: FormValues
): DigitalMapParams => {
  const floor1Overlay = values.overlays.find(
    (overlay) => overlay.floorNumber === 1
  );

  return {
    name: values.name,
    map_version: values.version,
    background: {
      do_not_use_overlay_image: !values.useOverlayImage,
      image_url: floor1Overlay?.imageUrl ?? '',
      top_left: {
        latitude: floor1Overlay?.topLeft.latitude ?? 0,
        longitude: floor1Overlay?.topLeft.longitude ?? 0,
      },
      image_projection_width_in_meters: floor1Overlay?.widthInMeters ?? 0,
      image_aspect_ratio: {
        width: floor1Overlay?.aspectRatio.width ?? 0,
        height: floor1Overlay?.aspectRatio.height ?? 0,
      },
      use_overlay_tiling: values.useOverlayTiling,
    },
    floor_overlays: values.overlays
      .filter((overlay) => overlay.floorNumber !== 1)
      .map((overlay) => ({
        floor_number: overlay.floorNumber,
        image_url: overlay.imageUrl,
        top_left: overlay.topLeft,
        image_projection_width_in_meters: overlay.widthInMeters,
        image_aspect_ratio: overlay.aspectRatio,
      })),
    pins: values.pins.map((pin) => ({
      title: pin.title,
      description: pin.description,
      thumbnail_url: pin.thumbnailUrl,
      icon_url: pin.iconUrl,
      icon_image_fit: pin.iconFit,
      use_thumbnail_image_pin_marker: pin.useThumbnailImagePinMarker,
      tags: pin.tags,
      structured_info_items: pin.structuredInfoItems?.map((item) => ({
        info_category_key: item.infoCategoryKey,
        values: item.values,
      })),
      location: {
        latitude: pin.location.latitude,
        longitude: pin.location.longitude,
      },
      category: pin.category,
      show_wait_time: pin.showWaitTime,
      wait_time: pin.waitTime,
      time_data_type:
        pin.timeDataType === 'NONE' ? undefined : pin.timeDataType,
      showtime_schedules: pin.showtimeSchedules?.map((sched) => ({
        showtimes: sched.showtimes,
        schedule: {
          date_ranges: sched.schedule.dateRanges.map((dateRange) => ({
            start_date_local: dateRange.startDate,
            end_date_local: dateRange.endDate,
          })),
          days_of_week: sched.schedule.weekdays,
          closed_dates: sched.schedule.closedDates
            .filter((closedDate) => !closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
          annually_repeating_closed_dates: sched.schedule.closedDates
            .filter((closedDate) => closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
        },
      })),
      open_hour_schedules: pin.openHourSchedules?.map((sched) => ({
        start_time_local: sched.openFrom,
        end_time_local: sched.openTo,
        schedule: {
          date_ranges: sched.schedule.dateRanges.map((dateRange) => ({
            start_date_local: dateRange.startDate,
            end_date_local: dateRange.endDate,
          })),
          days_of_week: sched.schedule.weekdays,
          closed_dates: sched.schedule.closedDates
            .filter((closedDate) => !closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
          annually_repeating_closed_dates: sched.schedule.closedDates
            .filter((closedDate) => closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
        },
      })),
      showtimes: pin.timeDataType === 'SHOWTIMES' ? pin.showtimes : [],
      operating_hours:
        pin.timeDataType === 'TIME_RANGE'
          ? {
              start_time_local: pin.openFrom,
              end_time_local: pin.openTo,
            }
          : undefined,
      media_items: convertMediaUrlsToProductMediaItems(
        pin.mediaUrls || []
      ) as any,
      associated_product_ids:
        pin.pinInteractionType === PinInteractionType.BOOKABLE_PRODUCT
          ? pin.associatedProductIds
          : [],
      generic_action_button:
        pin.pinInteractionType === PinInteractionType.CUSTOM
          ? {
              button_text: pin.genericActionButton.buttonText,
              destination_url: pin.genericActionButton.destinationUrl,
              destination_display: pin.genericActionButton.destinationDisplay,
            }
          : undefined,
      coupon_ids: pin.couponIds,
      should_use_stamp_rally: pin.shouldUseStampRally,
      stamp_rally_label: pin.stampRallyLabel,
      stamp_image_url: pin.stampImageUrl,
      activation_range: pin.activationDistance,
      free_form_content_items: pin.showFreeFormDescription
        ? [
            {
              content_tag: 'DESCRIPTION',
              data: JSON.stringify(pin.freeFormDescription),
            },
          ]
        : [],
      online_order_restaurant_id:
        pin.pinInteractionType === PinInteractionType.RESTAURANT &&
        pin.pinRestaurantType === PinRestaurantType.ONLINE_ORDER
          ? pin.onlineOrderRestaurantId
          : undefined,
      open_table_restaurant_id:
        pin.pinInteractionType === PinInteractionType.RESTAURANT &&
        pin.pinRestaurantType === PinRestaurantType.OPEN_TABLE
          ? pin.openTableRestaurantId
          : undefined,
      floor_number: pin.floorNumber,
      key: pin.key,
    })),
    pin_categories: values.pinCategories.map((pinCategory) => ({
      label: pinCategory.label,
      icon_url: pinCategory.iconUrl,
      initially_selected: pinCategory.initiallySelected,
    })),
    structured_info_categories: values.structuredInfoCategories.map(
      (structuredInfoCategory) => ({
        label: structuredInfoCategory.label,
        icon_url: structuredInfoCategory.iconUrl,
      })
    ),
    stamp_rally_id: values.stampRallyId,
    map_rotation: values.mapRotation,
    default_map_center: {
      latitude: values.mapCenter.latitude,
      longitude: values.mapCenter.longitude,
    },
    default_map_zoom: values.mapZoom,
    product_ids: values.productIds,
    ga4_google_analytics_tag: values.ga4AnalyticsTag,
    disable_including_map_link_in_customer_emails:
      values.disableIncludingMapLinkInCustomerEmails,
    seo_params: {
      title: values.seoParams.title,
      meta_description: values.seoParams.metaDescription,
      thumbnail_url: values.seoParams.thumbnailUrl,
      custom_domain: values.seoParams.customDomain,
      language: values.seoParams.language,
    },
    map_floor_count: values.floorCount,
    display_settings: {
      custom_google_map_id: values.customGoogleMapId,
      menu_items: values.menuItems,
      custom_menu_items: values.customMenuItems.map((item) => ({
        label: item.label,
        url: item.url,
        item_type: item.itemType,
        sub_menu_items: item.subMenuItems.map((subItem) => ({
          label: subItem.label,
          url: subItem.url,
          item_type: subItem.itemType,
        })),
      })),
      language_menu_items: values.showLanguageMenu
        ? values.languageMenuItems.map((item) => ({
            language: item.language,
            digital_map_id: item.mapId,
          }))
        : [],
      show_uber_button_for_pins: values.showUberButtonForPins,
      show_qr_code_button: values.showQrCodeButton,
      enable_high_resolution_tiles: values.enableHighResolutionTiles,
      show_chatbot_button: values.showChatbotButton,
      recommended_itinerary_urls: values.recommendedItineraryUrls,
    },
    bus_routes: values.showBusRoutes
      ? values.busRoutes.map((route) => ({
          path: route.path,
        }))
      : [
          {
            path: '',
          },
        ],
  };
};

export const convertSwaggerToFormValues = (
  digitalMap: DigitalMap
): FormValues => {
  return {
    name: digitalMap.name ?? '',
    useOverlayImage: !digitalMap.background?.do_not_use_overlay_image,
    useOverlayTiling: digitalMap.background?.use_overlay_tiling ?? false,
    overlays: [
      {
        floorNumber: 1,
        imageUrl: digitalMap.background?.image_url ?? '',
        topLeft: {
          latitude: digitalMap.background?.top_left?.latitude ?? 0,
          longitude: digitalMap.background?.top_left?.longitude ?? 0,
        },
        widthInMeters:
          digitalMap.background?.image_projection_width_in_meters ?? 0,
        aspectRatio: {
          width: digitalMap.background?.image_aspect_ratio?.width ?? 0,
          height: digitalMap.background?.image_aspect_ratio?.height ?? 0,
        },
      },
      ...(digitalMap.floor_overlays ?? []).map((overlay) => ({
        floorNumber: overlay.floor_number ?? 0,
        imageUrl: overlay.image_url ?? '',
        topLeft: {
          latitude: overlay.top_left?.latitude ?? 0,
          longitude: overlay.top_left?.longitude ?? 0,
        },
        widthInMeters: overlay.image_projection_width_in_meters ?? 0,
        aspectRatio: {
          width: overlay.image_aspect_ratio?.width ?? 0,
          height: overlay.image_aspect_ratio?.height ?? 0,
        },
      })),
    ],
    pins:
      _.orderBy(
        digitalMap.pins,
        ['floor_number', 'category', 'title'],
        ['asc', 'asc', 'asc']
      )?.map((pin) => ({
        title: pin.title ?? '',
        description: pin.description ?? '',
        thumbnailUrl: pin.thumbnail_url ?? '',
        iconUrl: pin.icon_url ?? '',
        iconFit: pin.icon_image_fit ?? 'CONTAIN',
        useThumbnailImagePinMarker: pin.use_thumbnail_image_pin_marker ?? false,
        tags: pin.tags ?? [],
        structuredInfoItems:
          pin.structured_info_items?.map((item) => ({
            infoCategoryKey: item.info_category_key ?? '',
            values: item.values ?? [],
          })) ?? [],
        location: {
          latitude: pin.location?.latitude ?? 0,
          longitude: pin.location?.longitude ?? 0,
        },
        category: pin.category ?? '',
        showWaitTime: pin.show_wait_time ?? false,
        waitTime: pin.wait_time ?? 0,
        timeDataType: (pin.time_data_type ?? 'NONE') as Pin['timeDataType'],
        showtimes: pin.showtimes ?? [],
        openFrom: pin.operating_hours?.start_time_local ?? '',
        openTo: pin.operating_hours?.end_time_local ?? '',
        openHourSchedules:
          pin.open_hour_schedules?.map((sched) => ({
            openFrom: sched.start_time_local ?? '0:00',
            openTo: sched.end_time_local ?? '0:00',
            schedule: {
              dateRanges:
                sched.schedule?.date_ranges?.map((dateRange) => ({
                  startDate: dateRange.start_date_local ?? '',
                  endDate: dateRange.end_date_local ?? '',
                })) ?? [],
              weekdays: sched.schedule?.days_of_week ?? [],
              closedDates: _.sortBy(
                [
                  ...(sched.schedule?.closed_dates?.map((closedDate) => ({
                    date: closedDate,
                    repeatsAnnually: false,
                  })) ?? []),
                  ...(sched.schedule?.annually_repeating_closed_dates?.map(
                    (closedDate) => ({
                      date: closedDate,
                      repeatsAnnually: true,
                    })
                  ) ?? []),
                ],
                'date'
              ),
            },
          })) ?? [],

        showtimeSchedules:
          pin.showtime_schedules?.map((sched) => ({
            showtimes: sched.showtimes ?? [],
            schedule: {
              dateRanges:
                sched.schedule?.date_ranges?.map((dateRange) => ({
                  startDate: dateRange.start_date_local ?? '',
                  endDate: dateRange.end_date_local ?? '',
                })) ?? [],
              weekdays: sched.schedule?.days_of_week ?? [],
              closedDates: _.sortBy(
                [
                  ...(sched.schedule?.closed_dates?.map((closedDate) => ({
                    date: closedDate,
                    repeatsAnnually: false,
                  })) ?? []),
                  ...(sched.schedule?.annually_repeating_closed_dates?.map(
                    (closedDate) => ({
                      date: closedDate,
                      repeatsAnnually: true,
                    })
                  ) ?? []),
                ],
                'date'
              ),
            },
          })) ?? [],
        mediaUrls: pin?.media_items?.length
          ? pin.media_items?.map((item) => item.url) ?? []
          : pin.thumbnail_url
          ? [pin.thumbnail_url]
          : [],
        pinInteractionType: pin.associated_product_ids?.length
          ? PinInteractionType.BOOKABLE_PRODUCT
          : pin.online_order_restaurant_id || pin.open_table_restaurant_id
          ? PinInteractionType.RESTAURANT
          : pin.generic_action_button?.destination_url
          ? PinInteractionType.CUSTOM
          : PinInteractionType.NONE,
        pinRestaurantType: pin.open_table_restaurant_id
          ? PinRestaurantType.OPEN_TABLE
          : PinRestaurantType.ONLINE_ORDER,
        associatedProductIds: pin?.associated_product_ids ?? [],
        genericActionButton: pin.generic_action_button?.destination_url
          ? {
              buttonText: pin.generic_action_button.button_text ?? '',
              destinationUrl: pin.generic_action_button.destination_url ?? '',
              destinationDisplay:
                pin.generic_action_button.destination_display ?? 'IFRAME',
            }
          : {
              buttonText: '',
              destinationUrl: '',
              destinationDisplay: 'IFRAME',
            },
        couponIds: pin?.coupon_ids ?? [],
        shouldUseStampRally: pin.should_use_stamp_rally ?? false,
        stampRallyLabel: pin.stamp_rally_label ?? '',
        stampImageUrl: pin.stamp_image_url ?? '',
        activationDistance: pin.activation_range ?? 0,
        showFreeFormDescription:
          pin.free_form_content_items?.some(
            (item) => item.content_tag === 'DESCRIPTION'
          ) ?? false,
        freeFormDescription: parse(
          pin.free_form_content_items?.find(
            (item) => item.content_tag === 'DESCRIPTION'
          )?.data
        ),
        onlineOrderRestaurantId: pin.online_order_restaurant_id ?? '',
        openTableRestaurantId: pin.open_table_restaurant_id ?? '',
        key: pin.key ?? uuidv4(),
        floorNumber: pin.floor_number ?? 1,
      })) ?? [],
    pinCategories:
      digitalMap.pin_categories?.map((pinCategory) => ({
        label: pinCategory.label ?? '',
        iconUrl: pinCategory.icon_url ?? '',
        initiallySelected: pinCategory.initially_selected ?? false,
      })) ?? [],
    structuredInfoCategories:
      digitalMap.structured_info_categories?.map((structuredInfoCategory) => ({
        label: structuredInfoCategory.label ?? '',
        iconUrl: structuredInfoCategory.icon_url ?? '',
      })) ?? [],
    editingMode: 'NORMAL',
    showOverlayOnMap: true,

    stampRallyId: digitalMap.stamp_rally_id ?? '',
    mapRotation: digitalMap.map_rotation ?? 0,
    mapZoom: digitalMap.default_map_zoom ?? 18,
    mapCenter: {
      latitude: digitalMap.default_map_center?.latitude ?? 0,
      longitude: digitalMap.default_map_center?.longitude ?? 0,
    },
    version: digitalMap.map_version ?? 'V1',
    productIds: digitalMap.product_ids ?? [],
    ga4AnalyticsTag: digitalMap.ga4_google_analytics_tag ?? '',
    disableIncludingMapLinkInCustomerEmails:
      digitalMap.disable_including_map_link_in_customer_emails ?? false,
    seoParams: {
      title: digitalMap.seo_params?.title ?? '',
      metaDescription: digitalMap.seo_params?.meta_description ?? '',
      thumbnailUrl: digitalMap.seo_params?.thumbnail_url ?? '',
      customDomain: digitalMap.seo_params?.custom_domain ?? '',
      language: digitalMap.seo_params?.language ?? 'EN_US',
    },
    floorCount: digitalMap.map_floor_count ?? 1,
    menuItems: digitalMap.display_settings?.menu_items ?? [
      'MAP',
      'MY_ACCOUNT',
      'TICKETS',
      'SCHEDULE',
      'STAMP_RALLY',
    ],
    customMenuItems:
      (digitalMap.display_settings?.custom_menu_items ?? []).length > 0
        ? (digitalMap.display_settings?.custom_menu_items ?? []).map(
            (item) => ({
              id: uuidv4(),
              label: item.label ?? '',
              url: item.url ?? '',
              itemType: item.item_type ?? 'CUSTOM_LINK',
              subMenuItems:
                item.sub_menu_items?.map((subItem) => ({
                  id: uuidv4(),
                  label: subItem.label ?? '',
                  url: subItem.url ?? '',
                  subMenuItems: [],
                  itemType: 'CUSTOM_LINK',
                })) ?? [],
            })
          )
        : (
            digitalMap.display_settings?.menu_items ?? [
              'MAP',
              'MY_ACCOUNT',
              'TICKETS',
              'SCHEDULE',
              'STAMP_RALLY',
            ]
          ).map((item) => ({
            id: uuidv4(),
            label: '',
            url: '',
            itemType: item,
            subMenuItems: [],
          })),
    customGoogleMapId: digitalMap.display_settings?.custom_google_map_id ?? '',
    showLanguageMenu:
      (digitalMap.display_settings?.language_menu_items?.length ?? 0) > 0,
    languageMenuItems:
      digitalMap.display_settings?.language_menu_items?.map((item) => ({
        language: item.language ?? 'EN_US',
        mapId: item.digital_map_id ?? '',
      })) ?? [],
    showUberButtonForPins:
      digitalMap.display_settings?.show_uber_button_for_pins ?? false,
    showQrCodeButton: digitalMap.display_settings?.show_qr_code_button ?? false,
    enableHighResolutionTiles:
      digitalMap.display_settings?.enable_high_resolution_tiles ?? false,
    showChatbotButton:
      digitalMap.display_settings?.show_chatbot_button ?? false,
    showBusRoutes: (digitalMap.bus_routes?.length ?? 0) > 0,
    busRoutes:
      digitalMap.bus_routes?.map((route) => ({
        path: route.path ?? '',
      })) ?? [],
    showRecommendedItinerary:
      (digitalMap.display_settings?.recommended_itinerary_urls?.length ?? 0) >
      0,
    recommendedItineraryUrls:
      digitalMap.display_settings?.recommended_itinerary_urls ?? [],
  };
};

export const defaultInitialValues: FormValues = {
  name: '',
  useOverlayImage: true,
  useOverlayTiling: false,
  overlays: [
    {
      floorNumber: 1,
      imageUrl: '',
      topLeft: {
        latitude: 0,
        longitude: 0,
      },
      widthInMeters: 0,
      aspectRatio: {
        width: 0,
        height: 0,
      },
    },
  ],
  pins: [],
  customGoogleMapId: '',
  pinCategories: [],
  structuredInfoCategories: [],
  editingMode: 'NORMAL',
  showOverlayOnMap: true,
  stampRallyId: '',
  mapRotation: 0,
  mapZoom: 20,
  mapCenter: {
    latitude: 0,
    longitude: 0,
  },
  version: 'V2',
  productIds: [],
  ga4AnalyticsTag: '',
  disableIncludingMapLinkInCustomerEmails: false,
  seoParams: {
    title: '',
    metaDescription: '',
    thumbnailUrl: '',
    customDomain: '',
    language: 'EN_US',
  },
  floorCount: 1,
  menuItems: config.enableCustomMapHeaderMenuSettings
    ? []
    : ['MAP', 'MY_ACCOUNT', 'TICKETS', 'SCHEDULE', 'STAMP_RALLY'],
  customMenuItems: config.enableCustomMapHeaderMenuSettings
    ? (
        [
          'MAP',
          'MY_ACCOUNT',
          'TICKETS',
          'SCHEDULE',
          'STAMP_RALLY',
        ] as MenuItem[]
      ).map((item) => ({
        id: uuidv4(),
        label: '',
        url: '',
        itemType: item,
        subMenuItems: [],
      }))
    : [],
  showLanguageMenu: false,
  languageMenuItems: [],
  showUberButtonForPins: false,
  showQrCodeButton: false,
  enableHighResolutionTiles: false,
  showChatbotButton: false,
  showBusRoutes: false,
  busRoutes: [],
  showRecommendedItinerary: false,
  recommendedItineraryUrls: [],
};

export const calculateDefaultMapCenter = (values: FormValues): Location => {
  const floor1Overlay = values.overlays.find(
    (overlay) => overlay.floorNumber === 1
  );

  const heightInMeters =
    (floor1Overlay?.widthInMeters ?? 0) *
    ((floor1Overlay?.aspectRatio.height ?? 0) /
      (floor1Overlay?.aspectRatio.width ?? 0));

  const diagonalLength = Math.sqrt(
    Math.pow(floor1Overlay?.widthInMeters ?? 0, 2) +
      Math.pow(heightInMeters ?? 0, 2)
  );

  const angle =
    (Math.atan(heightInMeters / (floor1Overlay?.widthInMeters ?? 0)) * 180) /
    Math.PI;

  const center = google.maps.geometry.spherical.computeOffset(
    new google.maps.LatLng(
      floor1Overlay?.topLeft.latitude ?? 0,
      floor1Overlay?.topLeft.longitude ?? 0
    ),
    diagonalLength / 2,
    values.mapRotation + 90 + angle
  );

  return {
    latitude: center.lat(),
    longitude: center.lng(),
  };
};

export const getDefaultPin = (): Pin => ({
  title: '[New Pin]',
  description: '',
  thumbnailUrl: '',
  iconUrl: '',
  iconFit: 'CONTAIN',
  useThumbnailImagePinMarker: false,
  tags: [],
  structuredInfoItems: [],
  location: {
    latitude: 0,
    longitude: 0,
  },
  showWaitTime: false,
  waitTime: 0,
  timeDataType: 'NONE',
  openFrom: '',
  openTo: '',
  showtimes: [],
  openHourSchedules: [],
  showtimeSchedules: [],
  mediaUrls: [],
  associatedProductIds: [],
  couponIds: [],
  shouldUseStampRally: false,
  stampRallyLabel: '',
  stampImageUrl: '',
  activationDistance: 0,
  showFreeFormDescription: false,
  freeFormDescription: '',
  onlineOrderRestaurantId: '',
  openTableRestaurantId: '',
  floorNumber: 1,
  key: uuidv4(),
  pinInteractionType: PinInteractionType.NONE,
  pinRestaurantType: PinRestaurantType.ONLINE_ORDER,
  genericActionButton: {
    buttonText: '',
    destinationUrl: '',
    destinationDisplay: 'IFRAME',
  },
});
